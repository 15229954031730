import { SliceZone } from "@prismicio/react";
import { graphql } from "gatsby";
import React from "react";
import components from "../components/slices";
import Layout from "../components/common/Layout";

import { constructActiveDoc } from "../utilities";

const About = ({ data }) => {
  if (!data) return null;
  const { settings, page } = data;

  return (
    <Layout settings={settings} activeDocMeta={constructActiveDoc(page)}>
      <SliceZone slices={page.data.body} components={components} />
    </Layout>
  );
};

export const query = graphql`
  query aboutpageQuery($lang: String) {
    settings: prismicSettings(lang: { eq: $lang }) {
      ...prismicSettingsFragment
    }
    page: prismicAbout(lang: { eq: $lang }) {
      alternate_languages {
        uid
        type
        lang
      }
      lang
      type
      url
      data {
        meta_title
        meta_description
        meta_image {
          alt
          dimensions {
            height
            width
          }
          url
        }
        body {
          ... on PrismicAboutDataBodyPageDescription {
            id
            slice_type
            primary {
              description {
                richText
              }
              has_red_title
              rich_text_title {
                richText
              }
            }
          }
          ... on PrismicAboutDataBodyHero {
            id
            slice_type
            primary {
              hero_title
              image {
                alt
                gatsbyImageData(placeholder: BLURRED, layout: FULL_WIDTH)
              }
              mobile_image {
                alt
                gatsbyImageData(placeholder: BLURRED, layout: FULL_WIDTH)
              }
            }
          }
          ... on PrismicAboutDataBodyInfoPicture {
            id
            items {
              info_number
              info_prefix
              info_suffix
              info_label
            }
            primary {
              image_mobile {
                alt
                gatsbyImageData(placeholder: BLURRED, layout: FULL_WIDTH)
              }
              info_image {
                alt
                gatsbyImageData(placeholder: BLURRED, layout: FULL_WIDTH)
              }
            }
            slice_type
          }
        }
      }
    }
  }
`;

export default About;
